import * as React from "react"
import * as styles from "./coming-soon.module.css"
import * as sharedStyles from "./styles.module.css"
import data from "../content/data.json"
import Seo from "../components/seo"
import main from "../images/coming-soon/main.png"
import Text from "../components/text/text"
import Networks from "../components/molecules/networks"

const ComingSoon = () => (
  <div className={sharedStyles.container}>
    <Seo title="Coming Soon" />
    <div className={sharedStyles.content}>
      <img src={main} alt="Vineber Logo" className={styles.logo} />
      <Text type="heading1">{data["coming-soon"].title}</Text>
      <Text type="heading1" className={sharedStyles.textTitle}>{data["coming-soon"]["title2"]}</Text>
      <Text type="comingSoon" className={sharedStyles.text}>{data["coming-soon"].description}</Text>
      <Text type="comingSoonBold" className={sharedStyles.textTitle}>{data["coming-soon"].caption}</Text>
      <Text type="comingSoon" className={sharedStyles.text}>{data["coming-soon"]["join-text"]}</Text>
      <Networks />
    </div>
  </div>
)

export default ComingSoon